import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IMAGES_QUERY = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "galeria" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const renderFn = data => (
  <div className="columns is-centered">
    <div className="column is-half">
      <h1 className="title">Hotové práce</h1>
      <h2 className="subtitle">Hotové projekty u spokojných zákazníkov</h2>
      {data.allFile.edges.map(edge => (
        <figure
          key={edge.node.id}
          className="image"
          style={{ border: '1px solid black', marginBottom: '1em' }}
        >
          <Img
            fluid={edge.node.childImageSharp.fluid}
            alt={'vstavana skrina'}
          />
        </figure>
      ))}
    </div>
  </div>
)

const Gallery = () => (
  <Layout>
    <SEO title="Galéria" description="Galéria hotových prác" />
    <StaticQuery query={IMAGES_QUERY} render={renderFn} />
  </Layout>
)

export default Gallery
